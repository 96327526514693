
/**
 * doc.
 * https://blog.csdn.net/qq_37417446/article/details/106794558
 * https://github.com/fengyuanchen/cropperjs#events
 * demo
 * https://fengyuanchen.github.io/cropperjs/
 */
import {
  computed,
  defineComponent,
  ref,
  toRefs,
  unref,
  watch
} from 'vue';
import { Plus, Delete } from '@element-plus/icons';
import { ElMessage, ElMessageBox } from 'element-plus';
import VueCropper, { VueCropperMethods } from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';

import { DEFAULT_SIZE_LIMIT, ResponseError, uploadPhoto } from '@/services/api';
import { Photo } from '@/interfaces';
import { formatFileSize } from '@/utils/render';

export type UploadImageEvent = Photo;

export default defineComponent({
  components: {
    Plus,
    Delete,
    VueCropper
  },
  props: {
    source: {
      type: String,
      default: ''
    },
    croppable: {
      type: Boolean,
      default: true
    },
    removable: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9
    },
    type: {
      type: String,
      default: 'images'
    },
    fileType: {
      type: String,
      default: 'image/png'
    },
    sizeLimit: {
      type: Number,
      default: DEFAULT_SIZE_LIMIT
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/jpg'
    }
  },
  emits: ['upload', 'delete', 'loading'],
  setup(props, { emit }) {
    const { source, fileType, sizeLimit, accept } = toRefs(props);
    const imageFile = ref<Blob | null>(null);
    const croppedImageSource = ref('');
    const previewImageSource = ref(source.value);
    const isCropAndUpload = ref(false);

    const handleAcceptMap = (acceptType: string) =>
      acceptType.includes('image/')
        ? acceptType.split('image/')[1] : acceptType;

    const formattedAcceptFile = computed(() =>
      accept.value.split(' ').map(handleAcceptMap).join(' '));

    watch(source, (source) => {
      if (isCropAndUpload.value) return;
      previewImageSource.value = source;
    });

    const isLoading = ref(false);
    const showDialog = ref(false);
    const cropperRef = ref<VueCropperMethods | null>(null);

    const removeImage = () => {
      previewImageSource.value = '';

      emit('delete');
    };

    const upload = async(fileSize) => {
      isLoading.value = true;

      if (fileSize > sizeLimit.value) {
        ElMessage.error(`檔案超過 ${formatFileSize(sizeLimit.value)} `);
        isLoading.value = false;
        removeImage();
      } else {
        const { data } = await uploadPhoto({
          data: {
            photos: imageFile.value
          }
        });

        emit('upload', data[0] as Photo);

        isLoading.value = false;
      }
    };

    const handleImageChange = ({ raw }: UploadFile) => {
      imageFile.value = raw;

      if (props.croppable) {
        croppedImageSource.value = URL.createObjectURL(raw);
        showDialog.value = true;

        return;
      }

      previewImageSource.value = URL.createObjectURL(raw);

      upload(raw.size);
    };

    const cropAndUpload = async() => {
      isCropAndUpload.value = true;
      const cropper = unref(cropperRef);

      const canvas = cropper?.getCroppedCanvas();
      previewImageSource.value = canvas.toDataURL() || '';

      canvas.toBlob(async(blob) => {
        imageFile.value = blob;

        await upload(blob.size);

        showDialog.value = false;
      }, fileType.value);
    };

    const handleBeforeDialogClose = (done: () => void) => {
      ElMessageBox.confirm('是否確認要關閉裁切彈窗？', '警告', {
        confirmButtonText: '關閉',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          done();
        })
        .catch();
    };

    watch(isLoading, (newIsLoading) => {
      emit('loading', newIsLoading);
    });

    return {
      formattedAcceptFile,
      croppedImageSource,
      previewImageSource,
      showDialog,
      cropperRef,
      handleImageChange,
      removeImage,
      cropAndUpload,
      handleBeforeDialogClose,
      isLoading,
      isCropAndUpload,
      formatFileSize
    };
  }
});
